export class AcpBillpayComponentCtrl implements nsUtils.NsComponentController {
  constructor(
    private $state,
    private acpCoreDispatcher // nsComponentDecorator
  ) {
    'ngInject';
    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
  }

  public $onInit() {
    // Perform initialization here
    this.routeToDashboard();
  }

  private routeToDashboard() {
    'ngInject';
    this.$state.go('dashboard').then(() => {
      this.acpCoreDispatcher.toast.show.emit({
        toastClass: 'failure',
        content: 'redirect-toast:apps/billpay',
        hideDelay: 10000
      });
    });
  }
  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpBillpayComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpBillpayComponentCtrl,
  controllerAs: 'vm',
  require: {}
};
